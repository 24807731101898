<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <v-row dense>
      <v-col>
        <img
          src="/images/ll_new.jpeg"
          height="125px"
        >
      </v-col>
      <v-col>
        <p class="text-center display-4 mt-5 font-weight-bold">
          {{ campaignResult.mail_campaign.name }}
        </p>
        <p class="text-center display-3 mt-4 font-weight-bold">
          {{ formatDate(campaignResult.mail_campaign.start_matchback_date) }} - {{ formatDate(campaignResult.mail_campaign.end_matchback_date) }}
        </p>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <base-material-card
          color="#E80C8B"
          icon="mdi-currency-usd"
          title=""
        >
          <template v-slot:after-heading>
            <div class="display-2 mt-2 font-weight-bold">
              Average Rate of Return - {{ formatPriceWithCents(campaignResult.total_rate_of_return) }}
            </div>
          </template>
          <v-row dense>
            <v-col cols="6">
              <div>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="font-weight-black">
                          Quantity
                        </span>
                        <span class="font-weight-bold">
                          &mdash; {{ formatNumber(campaignResult.number_mailed) }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="font-weight-black">
                          Total Responses (RO's + Cars Sold)
                        </span>
                        <span class="font-weight-bold">
                          &mdash; {{ formatNumber(campaignResult.total_responses) }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="font-weight-black">
                          Total Revenue / Rate of Return
                        </span>
                        <span class="font-weight-bold">
                          &mdash; {{ formatPrice(campaignResult.total_gross) }} /  {{ formatPriceWithCents(campaignResult.total_rate_of_return) }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="font-weight-black">
                          Date Mailed
                        </span>
                        <span class="font-weight-bold">
                          &mdash; {{ formatDate(campaignResult.mail_campaign.mail_date) }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>                  
                </v-list>
                <div />
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="font-weight-black">
                          Daily Average Responses
                        </span>
                        <span class="font-weight-bold">
                          &mdash; {{ formatNumber(campaignResult.daily_average) }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="font-weight-black">
                          Weekly Average Responses
                        </span>
                        <span class="font-weight-bold">
                          &mdash; {{ formatNumber(campaignResult.weekly_average) }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="font-weight-black">
                          Transaction Rate
                        </span>
                        <span class="font-weight-bold">
                          &mdash; {{ campaignResult.transaction_rate }} %
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="font-weight-black">
                          Investment
                        </span>
                        <span class="font-weight-bold">
                          &mdash; {{ formatPrice(campaignResult.mail_campaign.campaign_cost) }} 
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div />
              </div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <base-material-card
          color="#E80C8B"
          icon="mdi-car"
          title=""
        >
          <template v-slot:after-heading>
            <div class="display-2 mt-2 font-weight-bold">
              Cars Sold
            </div>
          </template>
          <div>
            <p
              class="text-center mt-1 font-weight-bold"
              style="font-size:2em"
            >
              {{ formatNumber(campaignResult.number_sales) }}
            </p>
          </div>
          <div>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span class="font-weight-black">
                      Most Recent Sale Date
                    </span>
                    <span class="font-weight-bold">
                      &mdash; {{ formatDate(campaignResult.most_recent_sale_date) }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span class="font-weight-black">
                      Total Gross Profit
                    </span>
                    <span class="font-weight-bold">
                      &mdash; {{ formatPrice(campaignResult.sales_gross) }} <em>(From StoneEagle)</em>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span class="font-weight-black">
                      Mail ROI
                    </span>
                    <span class="font-weight-bold">
                      &mdash; {{ formatNumber(campaignResult.mail_roi) }} %
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>              
              <v-divider />
            </v-list>
            <div />
          </div>
        </base-material-card>
      </v-col>
      <v-col cols="6">
        <base-material-card
          color="#E80C8B"
          icon="mdi-wrench"
          title=""
        >
          <template v-slot:after-heading>
            <div class="display-2 mt-2 font-weight-bold">
              ROs
            </div>
          </template>
          <div>
            <p
              class="text-center mt-1 font-weight-bold"
              style="font-size:2em"
            >
              {{ formatNumber(campaignResult.number_ros) }}
            </p>
          </div>
          <div>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span class="font-weight-black">
                      Most Recent RO Date
                    </span>
                    <span class="font-weight-bold">
                      &mdash; {{ formatDate(campaignResult.most_recent_ro_date) }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span class="font-weight-black">
                      Customer Pay (Revenue / Profit)
                    </span>
                    <span class="font-weight-bold">
                      &mdash; {{ formatPrice(campaignResult.service_customer_total_sale) }}  /  {{ formatPrice(campaignResult.customer_pay_profit) }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span class="font-weight-black">
                      Warranty Pay (Revenue / Profit)
                    </span>
                    <span class="font-weight-bold">
                      &mdash; {{ formatPrice(campaignResult.service_warranty_total_sale) }}  /  {{ formatPrice(campaignResult.warranty_pay_profit) }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </v-list>
            <div />
          </div>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Week
                </th>
                <th class="text-left">
                  Week Date
                </th>
                <th class="text-left">
                  Number Of Sales
                </th>
                <th class="text-left">
                  Number Of ROs
                </th>
                <th class="text-left">
                  Customer Pay (Revenue / Profit)
                </th>
                <th class="text-left">
                  Warranty (Revenue / Profit)
                </th>
                <th class="text-left">
                  Gross Sales
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in campaignResult.sorted_weekly_summaries"
                :key="item.week_number"
              >
                <td>{{ item.week_number + 1 }}</td>
                <td>{{ formatDate(item.start_date, false) }} - {{ formatDate(item.end_date, false) }} </td>
                <td>{{ item.number_sales }}</td>
                <td>{{ item.number_ros }}</td>
                <td>{{ formatPrice(item.service_customer_total_sale) }} / {{ formatPrice(item.service_customer_total_sale - item.service_customer_total_cost) }}</td>
                <td>{{ formatPrice(item.service_warranty_total_sale) }} / {{ formatPrice(item.service_warranty_total_cost) }}</td>
                <td>{{ formatPrice(item.number_sales * campaignResult.avg_sales_gross) }} </td>
              </tr>
              <tr>
                <td
                  style="font-weight: bold"
                >
                  TOTALS
                </td><td /><td style="font-weight: bold">
                  {{ formatNumber(campaignResult.sorted_weekly_summaries.reduce((a, b) => +a + +b.number_sales, 0)) }}
                </td>
                <td style="font-weight: bold">
                  {{ formatNumber(campaignResult.sorted_weekly_summaries.reduce((a, b) => +a + +b.number_ros, 0)) }}
                </td>
                <td style="font-weight: bold">
                  {{ formatPrice(campaignResult.sorted_weekly_summaries.reduce((a, b) => +a + +b.service_customer_total_sale, 0)) }}  / {{ formatPrice(campaignResult.sorted_weekly_summaries.reduce((a, b) => +a + (+b.service_customer_total_sale - b.service_customer_total_cost), 0)) }}
                </td>
                <td style="font-weight: bold">
                  {{ formatPrice(campaignResult.sorted_weekly_summaries.reduce((a, b) => +a + +b.service_warranty_total_sale, 0)) }} / {{ formatPrice(campaignResult.sorted_weekly_summaries.reduce((a, b) => +a + (+b.service_warranty_total_sale - b.service_warranty_total_cost), 0)) }}
                </td>
                <td style="font-weight: bold">
                  {{ formatPrice(campaignResult.sorted_weekly_summaries.reduce((a, b) => +a + (+b.number_sales * campaignResult.avg_sales_gross), 0)) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import MailCampaignService from '@/services/MailCampaignService'

  export default {
    name: 'CampaignResults',
    components: {},
    mixins: [formatterMixin],
    data () {
      return {
        error: '',
        campaign: null,
        campaignResult: null,
        csr: null,
        total_responses: 0,
        items: [],
        mailCampaignId: null,
        aid: null,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
      },
    },
    created () {
      this.mailCampaignId = this.$route.params.mail_campaign_id
      this.aid = this.$route.query.aid
      this.loadMatchbackResults()
    },
    mounted () {
    },
    methods: {
      loadMatchbackResults: function () {
        this.items = []
        this.loading = true
        MailCampaignService.getMatchbackResults(this.mailCampaignId, this.aid)
          .then(response => {
            this.items = [response.data].flat()
            this.campaignResult = this.items[0]
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
    },
  }
</script>
<style>
  th {
    font-size: 16px!important;
    font-weight: bold!important;
  }
  td {
    font-size: 14px !important;
  }
  .theme--light.v-list-item .v-list-item__subtitle, .theme--light.v-list-item .v-list-item__action-text {
    color: rgba(0, 0, 0, 1);
  }
  .v-data-table td {
    font-weight: 500;
    padding: 14px 8px;
  }
  .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    font-size: 18px!important;
  }
</style>
