import axios from 'axios'

class MailCampaignService {
  getMailCampaigns () {
    return axios.get(
      '/mail_campaigns?',
    )
  }

  createMailCampaign (data) {
    return axios.post(
      '/mail_campaigns?name=' + data.name + '&mail_date=' + data.mailDate + '&art_due_date=' + data.artDueDate + 
      '&proof_to_client_date=' + data.proofToClientDueDate + '&report_due_date=' + data.reportDueDate + 
      '&start_matchback_date=' + data.matchbackStartDate + '&end_matchback_date=' + data.matchbackEndDate + 
      '&campaign_type=' + data.campaignType + '&number_mailed=' + data.numberMailed + '&campaign_cost=' + data.campaignCost,
    )
  }

  updateMailCampaign (data, campaignId) {
    return axios.patch(
      '/mail_campaigns/' + campaignId + '?name=' + data.name + '&mail_date=' + data.mailDate + '&art_due_date=' + data.artDueDate + 
      '&proof_to_client_date=' + data.proofToClientDueDate + '&report_due_date=' + data.reportDueDate + 
      '&start_matchback_date=' + data.matchbackStartDate + '&end_matchback_date=' + data.matchbackEndDate + 
      '&campaign_type=' + data.campaignType + '&number_mailed=' + data.numberMailed + '&campaign_cost=' + data.campaignCost,
    )
  }

  deleteMailCampaign (campaignId) {
    return axios.delete(
      '/mail_campaigns/' + campaignId,
    )
  }

  uploadFiles (data, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', data.file)

    return axios.post('/mail_campaigns/' + data.mailCampaignId + '/mail_campaign_files?description=' + data.description + '&manifest_file=' + data.manifestFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }

  getFiles (mailCampaignId) {
    return axios.get(
      '/mail_campaigns/' + mailCampaignId + '/mail_campaign_files/',
    )
  }

  deleteFile (mailCampaignId, fileId) {
    return axios.delete(
      '/mail_campaigns/' + mailCampaignId + '/mail_campaign_files/' + fileId,
    )
  }

  mergeFiles (data) {
    return axios.post(
      '/mail_campaigns/' + data.mailCampaignId + '/merge_files?headers=' + data.headers + '&combine_addresses=' + data.combineAddresses +
      '&remove_blank_addresses=' + data.removeBlankAddresses + '&remove_blank_names=' + data.removeBlankNames + '&filter_by_zip=' + data.filterByZip +
      '&file_ids=' + data.fileIds + '&merge_file_description=' + data.mergeFileDescription,
    )
  }

  createCampaignMatchback (mailCampaignId, startDate, endDate, mergeFileUrl) {
    return axios.post(
      '/mail_campaigns/' + mailCampaignId + '/campaign_matchbacks?start_date=' + startDate + '&end_date=' + endDate + '&merge_file_url=' + mergeFileUrl,
    )
  }

  getMatchbackResults (mailCampaignId, aid) {
    return axios.get(
      '/mail_campaigns/' + mailCampaignId + '/campaign_matchback_results?aid=' + aid,
    )
  }

  updateMatchbackResults (data) {
    return axios.patch(
      '/mail_campaigns/' + data.mailCampaignId + '/campaign_matchback_results/' + data.matchbackResultId + '/?number_mailed=' + data.numberMailed + '&campaign_cost=' + data.campaignCost,
    )
  }

  deleteMatchbackResult (data) {
    return axios.delete(
      '/mail_campaigns/' + data.mailCampaignId + '/campaign_matchback_results/' + data.matchbackResultId + '/',
    )
  }

  getMatchbackRequests (manifestUrls) {
    return axios.get(
      '/mail_campaigns/matchback_requests?manifest_urls=' + manifestUrls,
    )
  }
}
export default new MailCampaignService()
